import React, { useEffect, useState } from "react"
import Layout from "../../components/layout"
import AccountContent from "../../components/AccountContent"
import { Link } from "gatsby"
import "./my-account.scss"
import { gql, useQuery } from "@apollo/client"
import { isUserLoggedIn } from "../../utils/function"

function OrdersPage() {
  const ORDER_DATA = gql`
query orders($customerId : Int){
  orders(where: {customerId: $customerId}) {
    edges {
      node {
        orderNumber
        date
        status
        total
        lineItems {
          nodes {
            quantity
          }
        }
      }
    }
  }
}
`
const [orders, setOrders] = useState([])
const auth =isUserLoggedIn()
const customerId=auth!==null?auth.userID:null

const { error, loading, data, refetch } = useQuery(ORDER_DATA, {
  variables: { customerId: customerId }
})
if (typeof window !== "undefined") {

    useEffect(() => {
      if (auth) {
        refetch()
      }
    }, [auth])

    useEffect(() => {
      setOrders(data?.orders?.edges)
    }, [data])
  }
  return (
    <Layout>
      <div className="section my-account">
        <div className="container pd-row">
          <AccountContent>
            <table className="my-account__orders">
              <thead>
                <tr>
                  <th>Order</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Total</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  loading ? "loading..." :
                    orders?.map((order) => {
                      const order_date = new Date(order.node.date)
                      let total = 0
                      order.node.lineItems.nodes.map(item => {
                        total = total + item.quantity
                      })
                      return (
                        <tr>
                          <td data-title="Order">
                            <Link to="#">#{order.node.orderNumber}</Link>
                          </td>
                          <td data-title="Date">
                            <time>{order_date.getDate()}/{order_date.getMonth()}/{order_date.getFullYear()}</time>
                          </td>
                          <td data-title="Status">{order.node.status}</td>
                          <td data-title="Total">
                            {order.node.total} for {total} items
                          </td>
                          <td data-title="Actions">
                            <Link to="#" className="btn-action">View</Link>
                          </td>
                        </tr>
                      )
                    })
                }
              </tbody>
            </table>
          </AccountContent>
        </div>
      </div>
    </Layout>
  )
}

export default OrdersPage